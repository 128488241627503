import React, { Component } from "react";
import { Box } from "@material-ui/core";
import 'fontsource-roboto';
import { withStyles } from "@material-ui/core/styles";
import {
    Avatar, Badge,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Grid, Menu, MenuItem
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { blue, red } from "@material-ui/core/colors";
import girlAvatar from '../../assets/images/avatar-girl.png'
import { DateTime } from "luxon";
import {
    dispatchMeetingUUId,
    fetchTopics,
    joinMeeting,
    searchTopicsByTagID,
    showModal,
    subscribeTopic
} from "../../actions";
import { connect } from "react-redux";
import history from "../../history";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";
// import ReadMore from './readMore'
import ReadMore from "../readMore";


const styles = theme => ({
    avatar: {
        backgroundColor: red[500],
    },
    buttonJoin: {
        backgroundColor: blue[500],
        color: '#ffffff'
    },
    buttonFull: {
        backgroundColor: blue[500],
        color: '#ffffff'
    },
    indicator: {
        color: theme.palette.secondary.main
    },
    userName: {
        color: theme.palette.lightDimmer.default
    }
});


class UserCard extends Component {

    state = {
        topic: this.props.topic,
        localTimeZone: null,
        date: "",
        populationMax: 35,
        user: null,
        isShareMenuOpen: null,
        conferences: this.props.conferences,
    }

    componentDidMount() {
        this.setState({
            localTimeZone: DateTime.local().zoneName
        })
    }

    componentDidUpdate() {
        if (this.props?.user?.data && this.props.user !== this.state.user) {
            this.setState({
                user: this.props.user,
                localTimeZone: this.props.user.currentTimeZone
            })
            // if (this.state.conferences.data.timezone) {
            //     this.setState({
            //         user: this.props.user,
            //         localTimeZone: this.state.conferences.data.timezone ? this.state.conferences.data.timezone : DateTime.local().zoneName
            //     })
            // } else {
            //     this.setState({
            //         user: this.props.user,
            //         localTimeZone: this.props.user.data.timezone ? this.props.user.data.timezone : DateTime.local().zoneName
            //     })
            // }
        }

        if (this.props?.topic && this.props.topic !== this.state.topic)
            this.setState({
                topic: this.props.topic
            })
    }

    handleActions = (data, e) => {
        e.preventDefault()
        if (this.state.user && this.state.user.isLogIn) {
            history.push(data)
        } else {
            this.props.showModal('modal-login', '')
        }
    }

    subscribeButton = (userId, topicId) => {
        const { classes } = this.props;
        return (
            <Button
                onClick={() => {
                    this.props.subscribeTopic({ topic_id: topicId })
                        .then(() => {
                            // @ToDo add the subscribed topic to array of already subscribed topics
                            // without fetching all topics
                            this.props.fetchTopics(this.state.conferences.data.uuid)
                        })
                }}
                variant="contained"
                className={`${classes.buttonJoin} btn-join`}
            >
                Subscribe
            </Button>
        )
    }


    joinMeetingButton = (topicUUId, topicId) => {
        const { classes } = this.props;
        return (
            <Button
                onClick={() => {
                    this.props.dispatchMeetingUUId(topicUUId)
                    history.push(`/meeting/${this.state.conferences.data.uuid}/${topicUUId}`)
                    // window.location.reload()
                }}
                variant="contained"
                className={`${classes.buttonJoin} btn-join`}
            >
                Join
            </Button>
        )
    }


    renderUserDecisionButton = (topicUUId, hostId, topicId) => {
        const { classes } = this.props;


        //IF I'M LOGIN
        if (this.state.user?.isLogIn) {

            // IF THE TOPIC IS NOW
            if (this.isDateNow()) {
                //IF THE TOPIC IS NOT FULL
                if (!this.isTopicFull()) {
                    return this.joinMeetingButton(topicUUId, topicId)
                } else {
                    //IF THE TOPIC IS FULL
                    return (
                        <Button
                            disabled
                            variant="contained"
                            className={`${classes.buttonJoin} btn-join`}
                        >
                            Full
                        </Button>
                    )
                }
            } else {
                //IF THE TOPIC IS UPCOMING &
                //I'm the host
                if (this.state.user?.isLogIn && this.state.user.data.id === hostId) {
                    // return (
                    //     <Button
                    //         // onClick={(e) => {
                    //         //     this.handleActions('/video-call', e)
                    //         // }}
                    //         variant="contained"
                    //         className={`${classes.buttonJoin} btn-join`}
                    //     >
                    //         Host
                    //     </Button>
                    // )
                    if (this.subscribedTopic()) {
                        return (
                            <Button
                                disabled
                                variant="contained"
                                className={`${classes.buttonJoin} btn-join`}
                            >
                                On List
                            </Button>
                        )
                    } else {
                        return this.subscribeButton(this.state.user.data.id, topicId)
                    }
                }
                //IF IT'S NOT FULL
                if (!this.isTopicFull()) {
                    //IF I'M NOT SUBSCRIBED
                    if (!this.subscribedTopic()) {
                        return this.subscribeButton(this.state.user.data.id, topicId)
                    } else {
                        //IF I'M SUBSCRIBED
                        return (
                            <Button
                                disabled
                                variant="contained"
                                className={`${classes.buttonJoin} btn-join`}
                            >
                                On List
                            </Button>
                        )
                    }

                } else {
                    return (
                        <Button
                            disabled
                            variant="contained"
                            className={`${classes.buttonJoin} btn-join`}
                        >
                            Full
                        </Button>
                    )
                }
            }
        } else {
            //IF I'M NOT LOGIN
            //IF IT'S NOW
            if (this.isDateNow()) {
                return (
                    <Button
                        onClick={(e) => {
                            this.handleActions('/video-call', e)
                        }}
                        variant="contained"
                        className={`${classes.buttonJoin} btn-join`}
                    >
                        Join
                    </Button>
                )
            } else {
                return (
                    <Button
                        onClick={(e) => {
                            this.handleActions('/video-call', e)
                        }}
                        variant="contained"
                        className={`${classes.buttonJoin} btn-join`}
                    >
                        Subscribed
                    </Button>
                )
            }
        }

    }

    setTopicModal = (type, data, e) => {
        if (e) {
            e.preventDefault()
        }
        this.props.showModal(type, data)
    }


    updateTopic = (topic) => {
        if (this.state.user?.isLogIn && this.state.user.data.id === topic.host.id) {
            return (
                <IconButton
                    onClick={() => {
                        this.setTopicModal('modal-set-topic', topic)
                    }}
                    aria-label="edit"
                    style={{ marginLeft: '0.625rem', padding: '0.438rem' }}
                >
                    <EditIcon
                        style={{ fontSize: '1.375rem' }}
                    />
                </IconButton>
            )
        }
    }

    isDateNow = () => {
        // GET DATES BY TIMESTAMPS
        const startDate = DateTime.fromISO(this.state.topic.start_at).ts
        const endDate = DateTime.fromISO(this.state.topic.end_at).ts
        const now = Number(DateTime.local().toFormat('x'))

        return startDate < now && now < endDate
    }

    isTopicFull = () => {
        return this.state.topic.participants === this.state.topic.populationMax
    }

    subscribedTopic = () => {
        return this.state.topic.subscribed
    }

    renderTopicDate = (date) => {
        const currentZone = DateTime.local().zoneName;
        const defaultZone = this.state.localTimeZone;
        return this.isDateNow() ? "Now" : DateTime.fromISO(date).setZone(defaultZone, { keepLocalTime: true }).setZone(currentZone).toFormat('DD')
        // return this.isDateNow() ? "Now" : DateTime.fromISO(date, { zone: this.state.localTimeZone }).toFormat('DD')
    }

    setTopicFeedbackModal = (type, data, e) => {
        e.preventDefault()
        this.props.showModal(type, data)
    }

    showHostBadge = () => {
        if (this.state.user?.data && this.state.topic) {
            return this.state.user.data.id !== this.state.topic.host.id
        } else {
            return true
        }
    }

    fetchTopicsByTagID = (tagId) => {
        this.props.searchTopicsByTagID(tagId, this.state.user.data.id, this.state.conferences.data.uuid)
        // history.push('/')
    }

    convertDate = (date) => {
        const currentZone = DateTime.local().zoneName;
        const defaultZone = this.state.localTimeZone;
        return DateTime.fromISO(date).setZone(defaultZone, { keepLocalTime: true }).setZone(currentZone).toLocaleString(DateTime.TIME_SIMPLE);
        // return DateTime.fromISO(date, { zone: this.state.localTimeZone }, DateTime.local()).toLocaleString(DateTime.TIME_SIMPLE)
    }

    openShareMenu = (e) => {
        this.setState({
            isShareMenuOpen: e.currentTarget
        })
    }

    closeShareMenu = () => {
        this.setState({
            isShareMenuOpen: null
        })
    }

    renderCardContent = () => {
        const { classes } = this.props;
        return (
            <CardContent>
                <Grid container>
                    {/*Avatar*/}
                    <Grid item className='user-card__item'>
                        <Badge badgeContent='HOST' color="secondary" invisible={this.showHostBadge()}>
                            <Avatar
                                aria-label="recipe"
                                className={classes.avatar}
                                alt={this.state.topic.host.name}
                                src={this.state.topic.host.avatar ? this.state.topic.host.avatar : girlAvatar}
                            />
                        </Badge>
                    </Grid>
                    {/*!Avatar*/}

                    {/*Topic title and user name*/}
                    <Grid item className='user-card__item user-card__text'>
                        <Typography variant='h6' className="user-card__item__title-name">
                            {this.state.topic.title}
                        </Typography>
                        <Typography variant='body1' className={`${classes.userName} user-card__item__user-name`}>
                            {this.state.topic.host.name}
                        </Typography>
                        <Box my={1}>
                            <ReadMore text={this.state.topic.description} />
                        </Box>
                    </Grid>
                    {/*T!opic title and user name*/}

                    <Grid item className='user-card__item'>
                        <Grid container alignItems='center' className='user-card__item__container'>
                            {/*Topic popularity and participants*/}
                            <Grid item>
                                <Typography className='user-card__room-population-indicator'>
                                    {
                                        this.isDateNow()
                                            ?
                                            this.state.topic.participants + '/' + this.state.populationMax
                                            :
                                            this.state.topic.popularity
                                    }
                                </Typography>
                            </Grid>
                            {/*!Topic popularity and participants*/}
                            <Grid item>
                                <div>
                                    {/*Topic Button*/}
                                    {this.renderUserDecisionButton(this.state.topic.uuid, this.state.topic.host.id, this.state.topic.id)}
                                    {/*!Topic Button*/}

                                    {/*Topic Update button*/}
                                    {this.updateTopic(this.state.topic)}
                                    {/*!Topic Update button*/}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justify={"flex-end"} className='user-card__item__container'>
                            <Grid item xs={12}>
                                <div>
                                    <Typography variant='h6' className='user-card__live-indicator'
                                        color='secondary'>
                                        {this.renderTopicDate(this.state.topic.start_at)}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justify={"flex-end"} className='user-card__item__container'>
                            <Grid item>
                                <Typography paragraph>
                                    {`${this.convertDate(this.state.topic.start_at)} - ${this.convertDate(this.state.topic.end_at)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container alignItems='center'>
                    <Grid item md={1} />
                    <Grid item>
                        <div className='user-card__tags'>
                            {/*TAGS OF THE TOPIC*/}
                            <ul>
                                {
                                    this.state.topic.tags.map((tag, index) => {
                                        return (
                                            <li key={index} className='tag'>
                                                <Chip
                                                    variant="outlined"
                                                    label={tag.name}
                                                    onClick={() => {
                                                        this.fetchTopicsByTagID(tag.id)
                                                    }}
                                                    clickable />
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </Grid>
                    <Grid item className='user-card__actions'>
                        <CardActions>
                            <Button
                                className='btn-shareable'
                                onClick={(e) => {
                                    this.setTopicFeedbackModal('modal-topic-feedback', {
                                        user: this.state.topic.host.name,
                                        topicId: this.state.topic.id,
                                        topic: this.state.topic.title
                                    }, e)
                                }}
                            >
                                {/*WARNING FLAG*/}
                                <div className='btn__wrap'>
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px"
                                        y="0px"
                                        width="100%" height="100%" viewBox="0 0 92 92">
                                        <path id="XMLID_2053_" d="M86.8,0.1c-1.9-0.4-3.8,0.6-4.5,2.4C81.2,5,70.6,16,61.5,16.4c-5.2,0.2-10-3.4-15.1-7.2
                                            C39.8,4.4,32.5-1,23.1,0.8C9.2,3.4,2.7,15.9,2.4,16.5c-0.4,0.8-0.5,1.8-0.3,2.7l4,17.9c0,0,0,0,0,0l11.7,51.9
                                            c0.4,1.9,2.1,3.1,3.9,3.1c0.3,0,0.6,0,0.9-0.1c2.2-0.5,3.5-2.6,3-4.8l-6.8-30.3c1.8-2.8,6.9-9.7,14.9-11.5c4.8-1.1,8.6,0.2,13,1.6
                                            c4.9,1.6,10.5,3.5,17.7,1.9C79.5,45.4,89.7,27.4,90,4C90,2.1,88.7,0.5,86.8,0.1z M62.6,41.1c-5,1.1-8.9-0.2-13.4-1.7
                                            c-4.9-1.6-10.4-3.5-17.3-1.9c-6.8,1.5-11.9,5.5-15.4,9.3L16.1,45l-5.9-26c1.7-2.6,6.4-8.8,14.3-10.3c6-1.1,11.1,2.6,17,7
                                            c5.7,4.2,12.3,9,20.2,8.7c6.7-0.3,13.6-4.2,19-8.6C78.4,27.8,72.5,38.8,62.6,41.1z"/>
                                    </svg>
                                </div>

                            </Button>

                            {/*SHARE*/}
                            <Button className='btn-shareable share'
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={(e) => {
                                    this.openShareMenu(e)
                                }}>
                                <div className='d-flex align-center'>
                                    <div className='btn__wrap'>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            x="0px" y="0px"
                                            width="100%" height="100%" viewBox="0 0 64 64">
                                            <g id="Layer_68" data-name="Layer 68">
                                                <path
                                                    d="M26.2,38.65a1.5,1.5,0,0,0,2.93.21c1.64-6.08,6.13-10.21,11.27-10.43v3.64a1.51,1.51,0,0,0,2.5,1.12l10.1-9a1.5,1.5,0,0,0,0-2.23l-10.1-9A1.51,1.51,0,0,0,40.4,14v3.72C31.51,18.19,24.74,28.59,26.2,38.65Z" />
                                                <path
                                                    d="M52,32a1.5,1.5,0,0,0-1.5,1.5V47.12c0,.75-.83,1.38-1.81,1.38H15.31c-1,0-1.81-.63-1.81-1.38V22c0-.74.83-1.38,1.81-1.38h9.22a1.5,1.5,0,0,0,0-3H15.31A4.62,4.62,0,0,0,10.5,22V47.12a4.62,4.62,0,0,0,4.81,4.38H48.69a4.62,4.62,0,0,0,4.81-4.38V33.5A1.5,1.5,0,0,0,52,32Z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div>
                                        <Typography variant='body2'>
                                            Share
                                        </Typography>
                                    </div>
                                </div>
                            </Button>
                            <Menu
                                id="simple-menu"
                                className='menu-share'
                                anchorEl={this.state.isShareMenuOpen}
                                keepMounted
                                open={Boolean(this.state.isShareMenuOpen)}
                                onClose={() => {
                                    this.closeShareMenu()
                                }}
                            >
                                {/*<MenuItem>*/}
                                {/*FacebookShareButton,*/}
                                {/*LinkedinShareButton,*/}
                                {/*TwitterShareButton,*/}
                                {/*</MenuItem>*/}
                                <MenuItem
                                    className='menu-share__item'
                                >
                                    <LinkedinShareButton
                                        windowWidth='800px'
                                        windowHeight='600px'
                                        url={process.env.REACT_APP_URL}
                                        source={process.env.REACT_APP_URL}
                                        summary={`Join me and others for a discussion on the topic '${this.state.topic.title}' hosted by ${this.state.topic.host.name} on ${DateTime.fromISO(this.state.topic.start_at).toFormat('D')}`}
                                        title={`Join me and others for a discussion on the topic '${this.state.topic.title}' hosted by ${this.state.topic.host.name} on ${DateTime.fromISO(this.state.topic.start_at).toFormat('D')}`}
                                    >
                                        <LinkedInIcon />
                                    </LinkedinShareButton>

                                </MenuItem>

                                <MenuItem
                                    className='menu-share__item'
                                >
                                    <FacebookShareButton
                                        url={process.env.REACT_APP_URL}
                                        quote={`Join me and others for a discussion on the topic '${this.state.topic.title}' hosted by ${this.state.topic.host.name} on ${DateTime.fromISO(this.state.topic.start_at).toFormat('D')}`}
                                        hashtag={'#SPARCmore'}
                                    >
                                        <FacebookIcon />
                                    </FacebookShareButton>

                                </MenuItem>
                                <MenuItem
                                    className='menu-share__item'
                                >
                                    <TwitterShareButton
                                        windowWidth='800px'
                                        windowHeight='600px'
                                        url={process.env.REACT_APP_URL}
                                        title={`Join me and others for a discussion on the topic '${this.state.topic.title}' hosted by ${this.state.topic.host.name} on ${DateTime.fromISO(this.state.topic.start_at).toFormat('D')}`}
                                    >
                                        <TwitterIcon />
                                    </TwitterShareButton>

                                </MenuItem>
                            </Menu>

                            {/*!SHARE*/}
                        </CardActions>
                    </Grid>
                </Grid>
            </CardContent>
        );
    }

    render() {
        return (
            <Card className='user-card__wrap' variant={"outlined"}>
                {
                    this.state.topic
                        ?
                        this.renderCardContent()
                        :
                        <Typography>
                            Loading ...
                        </Typography>

                }
            </Card>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        conferences: state.conferences
    };
}

const mapDispatchToProps = dispatch => ({
    showModal: (type, data) => dispatch(showModal(type, data)),
    subscribeTopic: (topic_id) => dispatch(subscribeTopic(topic_id)),
    joinMeeting: (topic_id) => dispatch(joinMeeting(topic_id)),
    fetchTopics: (data) => dispatch(fetchTopics(data)),
    searchTopicsByTagID: (tagId, userId, conferenceUUId) => dispatch(searchTopicsByTagID(tagId, userId, conferenceUUId)),
    dispatchMeetingUUId: (tagId) => dispatch(dispatchMeetingUUId(tagId)),
})

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(UserCard));
