import React, { useEffect, useState } from "react";

function DateCountdown({ date }) {

    // if (!date) {
    // return null;
    // } else {}

    const calculateTimeLeft = () => {
        if (!date) {
            return false;
        }
        const difference = +new Date(date) - +new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    const timerComponents = [];
    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }
        timerComponents.push(
            <span>{timeLeft[interval]} {interval}{" "}</span>
        );
    });

    if (!timerComponents.length) {
        window.location.reload();
    }

    return (
        <>
            {timerComponents.length ? timerComponents : "We are starting!"}
        </>
    );


}

export default DateCountdown;