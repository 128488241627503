import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { drawerToggle } from "../../actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { DateTime } from "luxon";
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";


const styles = theme => ({
    dateInputField: {
        '& .MuiInput-underline:after': {
            borderBottom: `2px solid #ffffff !important`,
        },
        '& label.Mui-focused': {
            color: `#ffffff`,
        }
    }
});

class DatePickerComp extends Component {

    state = {
        date: this.props.date || DateTime.local(),
        type: this.props.type,
        user: null,
        timezone: null,
        conferences: {},
        minDate: null,
        maxDate: null,
    }

    componentDidMount() {
        if (this.props?.userData?.data && this.state.user !== this.props.userData) {
            this.setState({
                user: this.props.userData,
                timezone: this.props.userData.currentTimeZone
            }, () => {
                // console.log(DateTime.local().setZone(this.state.timezone));
                // console.log(DateTime.local().toISO());
                // console.log(DateTime.local().setZone(this.state.timezone).toISO());
                // console.log(this.state.date);
                // console.log(DateTime.local().toISO());
            })
        }

        if (this.props.conferences && this.state.conferences !== this.props.conferences) {
            this.setState({
                conferences: this.props.conferences,
                minDate: this.props.conferences.data.start_at,
                maxDate: this.props.conferences.data.end_at
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    changeDate = (data) => {
        this.setState({ date: data })
    }

    dispatchDate = () => {
        this.props.datePickerData({ type: this.state.type, date: this.state.date });
    }


    render() {
        const { classes } = this.props;
        return (
            <div className='picker__date__wrap'>
                <KeyboardDatePicker
                    label="Date"
                    // autoOk='true'
                    className={classes.dateInputField}
                    PopoverProps={{
                        anchorOrigin: { horizontal: "right", vertical: "bottom" },
                        transformOrigin: { horizontal: "right", vertical: "top" }
                    }}
                    minDateMessage=''
                    fullWidth
                    variant="inline"
                    placeholder="Jan 14, 2021"
                    value={this.state.date}
                    onChange={(data) => {
                        this.changeDate(data)
                    }}
                    onClose={() => {
                        this.dispatchDate()
                    }}
                    minDate={DateTime.local()}
                    maxDate={this.state.maxDate ? DateTime.fromISO(this.state.maxDate) : undefined}
                    format="DD"
                />


            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        drawer: state.drawer,
        userData: state.user,
        conferences: state.conferences
    }
}

const mapDispatchToProps = dispatch => ({
    drawerToggle: data => dispatch(drawerToggle(data))
})

export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(DatePickerComp)));
