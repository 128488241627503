import React, { useState } from "react";

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = (e) => {
        e.preventDefault()
        setIsReadMore(!isReadMore);
    };
    return (
        <>
            {isReadMore ? text.slice(0, 140) : text}
            <a href="#" onClick={(e) => { toggleReadMore(e) }} style={{ color: '#fff', marginLeft: '0.5rem' }}>
                {
                    text.length > 140 ?
                        isReadMore ? "...read more" : "show less"
                        :
                        ''
                }
            </a>
        </>
    );
};

const Content = ({ text }) => {
    if (!text) {
        return ('');
    }
    return (
        <ReadMore>
            {text}
        </ReadMore>
    );
};

export default Content;